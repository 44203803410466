const Privacy = () => {
    return (
        <>
            <section className="py-10 bg-white sm:py-16 lg:py-24">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto text-center">
                        <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">Privacy Policy</h2>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">Last updated: September 1, 2024</p>
                    </div>

                    <div className="mt-8 space-y-8">
                        <div>
                            <h3 className="text-xl font-semibold text-black">1. Introduction</h3>
                            <p className="mt-2 text-base text-gray-600">Welcome to ConvoSearch. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our search engine services.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">2. Information We Collect</h3>
                            <p className="mt-2 text-base text-gray-600">We may collect information you provide directly to us, such as search queries, as well as information about your device and usage of our services.</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-black">3. How We Use Your Information</h3>
                            <p className="mt-2 text-base text-gray-600">We use the information we collect to provide, maintain, and improve our services, as well as to personalize your search experience.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">4. Sharing of Information</h3>
                            <p className="mt-2 text-base text-gray-600">We may share your information with third-party service providers to help us operate our business and deliver services to you.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">5. Data Security</h3>
                            <p className="mt-2 text-base text-gray-600">We implement appropriate technical and organizational measures to protect your personal information.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">6. Your Rights</h3>
                            <p className="mt-2 text-base text-gray-600">You have the right to access, correct, or delete your personal information. Please contact us to exercise these rights.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">7. Changes to This Privacy Policy</h3>
                            <p className="mt-2 text-base text-gray-600">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">8. Contact Us</h3>
                            <p className="mt-2 text-base text-gray-600">If you have any questions about this Privacy Policy, please contact us at shardul@convosearch.com.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacy