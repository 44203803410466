import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HeroSection from './Pages/Landing/Hero';
import BrandSection from './Pages/Landing/Brands';
import DemoSection from './Pages/Landing/Demo';
import AnalytisFeaturesSection from './Pages/Landing/Features/AnalyticsFeatures';
import ConversionFeaturesSection from './Pages/Landing/Features/Conversion';
import DocumentationSection from './Pages/Landing/Features/Documentation';
import CoreFeatures from './Pages/Landing/Features/CoreFeatures';
import StatSection from './Pages/Landing/Features/Stats';
import TestimonalSection from './Pages/Landing/Testimonial';
import Footer from './Pages/Footer';
import Header from './Pages/Header';
import Pricing from './Pages/Pricing/Pricing';
import Contact from './Pages/Contact/Contact';
import PricingSection from './Pages/Landing/Pricing';
import FaqSection from './Pages/Landing/Faq';
import Privacy from './Pages/Legal/Privacy';
import Terms from './Pages/Legal/Terms';

const App = () => {
  const ExternalRedirect = ({ to }: { to: string }) => {
    React.useEffect(() => {
      window.location.href = to;
    }, [to]);
    return null;
  };

  return (
    <Router>
      <div className="bg-gray-50">
        <Header />
        <hr className="border-t border-gray-100 mx-auto " />

      </div>
      <Routes>
        <Route path="/" element={
          <>
            <div className="bg-gray-50">
              {/* <Header /> */}
              <HeroSection />
            </div>
            <div className="bg-white ">
            {/* <StatSection /> */}

            <DemoSection />
            </div>
            <BrandSection />
            {/* <CoreFeatures /> */}
            <ConversionFeaturesSection />
            <AnalytisFeaturesSection />
            {/* <DocumentationSection /> */}
            <TestimonalSection />
            <PricingSection />
            {/* <FaqSection /> */}
          </>
        } />
        <Route path="/docs" element={<ExternalRedirect to="https://docs.convosearch.com" />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/pricing" element={<PricingSection/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        {/* <Route path="/careers" element={<Privacy/>} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;