import React, { useState } from 'react';


const ConversionFeaturesSection = () => {
    const ROICalculator = () => {
        const [avgProductValue, setAvgProductValue] = useState(50);
        const [monthlyTraffic, setMonthlyTraffic] = useState(100000);
        const [savings, setSavings] = useState(0);
    
        const calculateROI = () => {
            // Base savings of $20,000 for 100,000 traffic and $50 avg product value
            const baseSavings = 20000;
            const baseTraffic = 100000;
            const baseAvgProductValue = 50;
    
            // Calculate savings proportional to both inputs
            const trafficFactor = monthlyTraffic / baseTraffic;
            const valueFactor = avgProductValue / baseAvgProductValue;
            const monthlySavings = baseSavings * trafficFactor * valueFactor;
    
            setSavings(monthlySavings);
        };
    
        React.useEffect(() => {
            calculateROI();
        }, [avgProductValue, monthlyTraffic]);
    
        return (
            <>
                <div className="space-y-4 bg-white p-10 my-5 rounded-2xl">
                <div>
                <label htmlFor="avgProductValue" className="block text-sm font-medium text-gray-700">
                            Average Product Value ($)
                        </label>
                        <input
                            type="text"
                            id="avgProductValue"
                            value={`$${avgProductValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            onChange={(e) => setAvgProductValue(Number(e.target.value.replace(/[$,]/g, '')))}
                            placeholder="$50.00"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="monthlyTraffic" className="block text-sm font-medium text-gray-700">
                            Monthly Traffic
                        </label>
                        <input
                            type="text"
                            id="monthlyTraffic"
                            value={monthlyTraffic.toLocaleString()}
                            onChange={(e) => setMonthlyTraffic(Number(e.target.value.replace(/,/g, '')))}
                            placeholder="100,000"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>

                </div>
                <div className="mt-4 p-4 bg-green-100 border border-green-400 rounded-md">
                        <p className="text-lg font-semibold text-green-800">
                            Estimated Monthly Savings: ${savings.toFixed(2)}
                        </p>
                    </div>
                {/* <h2 className="mt-6 text-2xl font-semibold ">
                                Est. Savings: <span className='text-blue-600 font-bold text-4xl'>${savings}</span>/month.
                            </h2> */}
            </>
        )
    }
    return (
        <section className="py-12 bg-gray-100 sm:py-16 lg:py-20 xl:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid items-center grid-cols-1 lg:grid-cols-2 lg:gap-x-16 xl:gap-x-24 gap-y-12">
                    <div className="xl:pr-24 lg:order-2">
                    <h2 className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl mb-20" >Power your search with AI</h2>
                        <ul className="space-y-10 sm:space-y-14">
                            <li className="flex items-start">
                                <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                                </svg>
                                <p className="ml-3 text-xl font-normal text-gray-900"><span className="font-semibold">Personalized Recommendations:</span> Tailor suggestions based on user behavior.</p>
                            </li>

                            <li className="flex items-start">
                                <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                                </svg>
                                <p className="ml-3 text-xl font-normal text-gray-900"><span className="font-semibold">Enhanced Search Accuracy:</span> Improve search relevance to increase conversions.</p>
                            </li>

                            <li className="flex items-start">
                                <svg className="w-6 h-6 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                                </svg>
                                <p className="ml-3 text-xl font-normal text-gray-900"><span className="font-semibold">Data-Driven Recommendations:</span> Use data and trends to optimize product offerings.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="grid overflow-hidden bg-blue-100 lg:order-1 rounded-3xl place-items-center">
                        <div className="p-6 sm:p-12">
                            <h2 className="text-2xl font-semibold tracking-tight text-gray-900 sm:px-8 sm:text-3xl">
                                See how much you can save
                            </h2>
                            <ROICalculator />
                            {/* <img className="w-full mt-6 sm:mt-10" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/features/10/chart.png" alt="Revenue growth chart" /> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConversionFeaturesSection;