import React from 'react';
const Contact = () => {
    return (
<>
<section className="py-10 bg-white sm:py-16 lg:py-24">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto text-center">
                    <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">Pricing &amp; Plans</h2>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">Amet minim mollit non deserunt ullam co est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                </div>

                {/* <!-- lg+ --> */}
                <div className="hidden mt-16 lg:block">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="py-8 pr-4"></th>

                                <th className="px-4 py-8 text-center">
                                    <span className="text-base font-medium text-blue-600"> Free </span>
                                    <p className="mt-6 text-6xl font-bold">$0</p>
                                    <p className="mt-2 text-base font-normal text-gray-500">Per month</p>
                                </th>

                                <th className="px-4 py-8 text-center">
                                    <span className="text-base font-medium text-blue-600"> Team </span>
                                    <p className="mt-6 text-6xl font-bold">$99</p>
                                    <p className="mt-2 text-base font-normal text-gray-500">Per month</p>
                                </th>

                                <th className="px-4 py-8 text-center bg-gray-900 rounded-t-xl">
                                    <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full"> Popular </span>
                                    <p className="mt-6 text-6xl font-bold text-white">$150</p>
                                    <p className="mt-2 text-base font-normal text-gray-200">Per month</p>
                                </th>

                                <th className="px-4 py-8 text-center">
                                    <span className="text-base font-medium text-blue-600"> Enterprise </span>
                                    <p className="mt-6 text-6xl font-bold">$490</p>
                                    <p className="mt-2 text-base font-normal text-gray-500">Per month</p>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">Website number</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">01</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">10</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">50</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">Unlimited</td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">Server storage</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">100 GB</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">500 GB</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">1 TB</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">Unlimited</td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">Database</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">15</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">Unlimited</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">Unlimited</td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">Unmetered Bandwidth</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">SSD Disk</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">VCPUS Fontworld</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">WordPress install</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-4 pr-4 font-medium border-b border-gray-200">Server speed</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">-</td>

                                <td className="px-4 py-4 text-center text-white bg-gray-900 border-b border-white/20">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>

                                <td className="px-4 py-4 text-center border-b border-gray-200">
                                    <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-6 pr-4"></td>

                                <td className="px-4 py-6 text-center">
                                    <a href="#" title="" className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700">
                                        Get Started
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </a>
                                </td>

                                <td className="px-4 py-6 text-center">
                                    <a href="#" title="" className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700">
                                        Get Started
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </a>
                                </td>

                                <td className="px-4 py-6 text-center text-white bg-yellow-500 rounded-b-xl">
                                    <a href="#" title="" className="inline-flex items-center font-semibold text-white">
                                        Get Started
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </a>
                                </td>

                                <td className="px-4 py-6 text-center">
                                    <a href="#" title="" className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700">
                                        Get Started
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <!-- xs to lg --> */}
            <div className="block mt-12 border-t border-b border-gray-200 divide-y divide-gray-200 lg:hidden">
                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">
                        <span className="text-sm font-medium text-blue-600"> Free </span>
                        <p className="mt-2 text-xl font-bold">$0</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                    </div>

                    <div className="px-2 py-2">
                        <span className="text-sm font-medium text-blue-600"> Team </span>
                        <p className="mt-2 text-xl font-bold">$99</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                    </div>

                    <div className="px-2 py-2">
                        <span className="text-sm font-medium text-blue-600"> Popular </span>
                        <p className="mt-2 text-xl font-bold">$150</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                    </div>

                    <div className="px-2 py-2">
                        <span className="text-sm font-medium text-blue-600"> Enterprise </span>
                        <p className="mt-2 text-xl font-bold">$490</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                    </div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">Website number</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">01</div>

                    <div className="px-2 py-2">10</div>

                    <div className="px-2 py-2">100</div>

                    <div className="px-2 py-2">Unlimited</div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">Server storage</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">100 GB</div>

                    <div className="px-2 py-2">500 GB</div>

                    <div className="px-2 py-2">1 TB</div>

                    <div className="px-2 py-2">Unlimited</div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">Database</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">-</div>

                    <div className="px-2 py-2">15</div>

                    <div className="px-2 py-2">Unlimited</div>

                    <div className="px-2 py-2">Unlimited</div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">Unmetered bandwidth</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">-</div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">SSD Disk</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">-</div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">VCPUS Fontworld</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">-</div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                </div>

                <div className="px-2 py-4 sm:px-4">
                    <p className="font-semibold">WordPress install</p>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-2 py-2">-</div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>

                    <div className="px-2 py-2">
                        <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </div>
                </div>

                <div className="grid grid-cols-4 text-center divide-x divide-gray-200">
                    <div className="px-1 py-2 sm:px-4">
                        <span className="text-sm font-medium text-blue-600"> Free </span>
                        <p className="mt-2 text-xl font-bold">$0</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                        <a href="#" title="" className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700" role="button"> Get Started </a>
                    </div>

                    <div className="px-1 py-2 sm:px-4">
                        <span className="text-sm font-medium text-blue-600"> Team </span>
                        <p className="mt-2 text-xl font-bold">$99</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                        <a href="#" title="" className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700" role="button"> Get Started </a>
                    </div>

                    <div className="px-1 py-2 sm:px-4">
                        <span className="text-sm font-medium text-blue-600"> Popular </span>
                        <p className="mt-2 text-xl font-bold">$150</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                        <a href="#" title="" className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700" role="button"> Get Started </a>
                    </div>

                    <div className="px-1 pt-2 pb-4 sm:px-4">
                        <span className="text-sm font-medium text-blue-600"> Enterprise </span>
                        <p className="mt-2 text-xl font-bold">$490</p>
                        <span className="mt-1 text-sm font-normal text-gray-500"> Per month </span>
                        <a href="#" title="" className="flex items-center justify-center w-full px-1 py-2 mt-5 text-sm text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md hover:bg-blue-700" role="button"> Get Started </a>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Frequently Asked Questions</h2>
                    <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">Amet minim mollit non deserunt ullamco est sit aliqua dolor do</p>
                </div>

                <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                        <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                            <span className="flex text-lg font-semibold text-black"> How to create an account? </span>

                            <svg className="w-6 h-6 text-gray-400 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>

                        <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                            <p>Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.</p>
                        </div>
                    </div>

                    <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
                        <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                            <span className="flex text-lg font-semibold text-black"> How can I make payment using Paypal? </span>

                            <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>

                        <div className="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                            <p>Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.</p>
                        </div>
                    </div>

                    <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
                        <div className="">
                            <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                                <span className="flex text-lg font-semibold text-black"> Can I cancel my plan? </span>

                                <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            <div className="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                                <p>Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </div>

                    <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
                        <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                            <span className="flex text-lg font-semibold text-black"> How can I reach to support? </span>

                            <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>

                        <div className="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                            <p>Amet minim mollit non deserunt ullamco est sit <a href="#" title="" className="text-blue-600 transition-all duration-200 hover:underline">aliqua dolor</a> do amet sint. Velit officia consequat duis enim velit mollit.</p>
                        </div>
                    </div>
                </div>

                <p className="text-center text-gray-600 textbase mt-9">Didn’t find the answer you are looking for? <a href="#" title="" className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline">Contact our support</a></p>
            </div>
        </section>
        <section className="py-10 bg-white sm:pt-16 lg:pt-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-y-12 gap-x-8 xl:gap-x-12">
                    <div className="col-span-2 md:col-span-4 xl:pr-8">
                        <img className="w-auto h-9" src="/CS-logo.svg" alt="" />

                        <p className="text-base leading-relaxed text-gray-600 mt-7">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>

                        <a href="#" title="" className="inline-flex items-center justify-center px-6 py-4 font-semibold text-white transition-all duration-200 bg-blue-600 rounded-md hover:bg-blue-700 focus:bg-blue-700 mt-7">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                            </svg>
                            Start Live Chat
                        </a>
                    </div>

                    <div className="lg:col-span-2">
                        <p className="text-base font-semibold text-gray-900">Company</p>

                        <ul className="mt-6 space-y-5">
                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> About </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Features </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Works </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Career </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lg:col-span-2">
                        <p className="text-base font-semibold text-gray-900">Help</p>

                        <ul className="mt-6 space-y-4">
                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Customer Support </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Delivery Details </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Terms &amp; Conditions </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Privacy Policy </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lg:col-span-2">
                        <p className="text-base font-semibold text-gray-900">Resources</p>

                        <ul className="mt-6 space-y-5">
                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Free eBooks </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Development Tutorial </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> How to - Blog </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> YouTube Playlist </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lg:col-span-2">
                        <p className="text-base font-semibold text-gray-900">Extra Links</p>

                        <ul className="mt-6 space-y-5">
                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Customer Support </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Delivery Details </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Terms &amp; Conditions </a>
                            </li>

                            <li>
                                <a href="#" title="" className="flex text-sm text-gray-800 transition-all duration-200 hover:text-orange-600 focus:text-orange-600"> Privacy Policy </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <hr className="mt-16 mb-10 border-gray-200" />

                <div className="sm:flex sm:items-center sm:justify-between">
                    <p className="text-sm text-gray-600">© Copyright 2021, All Rights Reserved by Postcraft</p>

                    <ul className="flex items-center mt-5 space-x-3 md:order-3 sm:mt-0">
                        <li>
                            <a
                                href="#"
                                title=""
                                className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent border border-gray-300 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                            >
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                        d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                    ></path>
                                </svg>
                            </a>
                        </li>

                        <li>
                            <a
                                href="#"
                                title=""
                                className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent border border-gray-300 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                            >
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                                </svg>
                            </a>
                        </li>

                        <li>
                            <a
                                href="#"
                                title=""
                                className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent border border-gray-300 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                            >
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                                    <path
                                        d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"
                                    ></path>
                                </svg>
                            </a>
                        </li>

                        <li>
                            <a
                                href="#"
                                title=""
                                className="flex items-center justify-center text-gray-800 transition-all duration-200 bg-transparent border border-gray-300 rounded-full w-7 h-7 focus:bg-orange-600 hover:text-white focus:text-white hover:bg-orange-600 hover:border-orange-600 focus:border-orange-600"
                            >
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.085.635-1.334-2.214-.251-4.542-1.107-4.542-4.93 0-1.087.389-1.979 1.024-2.675-.101-.253-.446-1.268.099-2.64 0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336 9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021.545 1.372.203 2.387.099 2.64.64.696 1.024 1.587 1.024 2.675 0 3.833-2.33 4.675-4.552 4.922.355.308.675.916.675 1.846 0 1.334-.012 2.41-.012 2.737 0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974 22 6.465 17.535 2 12.026 2z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
</>
    )
}

export default Contact