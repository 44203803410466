import React, { useState } from 'react';

const DemoSection = () => {
    return (
        <section className="py-0">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto text-center mt-10 mb-20">
                <h2 className="text-5xl font-sans font-semibold tracking-tight text-black sm:text-4xl lg:text-5xl">See <span className="italic font-bold">Convo</span><span className="font-bold">Search</span> in Action!</h2>                
                </div>
                <div className="relative w-full max-w-6xl mx-auto mt-8 overflow-hidden rounded-t-lg sm:mt-20" style={{ height: 'auto' }}>
                    <iframe
                        className="w-full aspect-video"
                        src="https://www.youtube.com/embed/UHipwb70Q5I"
                        title="ConvoSearch Demo Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>


            </div>
        </section>
    )
}

export default DemoSection