const Terms = () => {
    return (
        <>
            <section className="py-10 bg-white sm:py-16 lg:py-24">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto text-center">
                        <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">Terms and Conditions</h2>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">Last updated: September 1, 2024</p>
                    </div>

                    <div className="mt-8 space-y-8">
                        <div>
                            <h3 className="text-xl font-semibold text-black">1. Service Description</h3>
                            <p className="mt-2 text-base text-gray-600">ConvoSearch provides a search-as-a-service API for ecommerce websites. Our service allows integration of advanced search capabilities into your online store.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">2. API Usage and Limitations</h3>
                            <p className="mt-2 text-base text-gray-600">Use of our API is subject to rate limits and usage restrictions as outlined in your service plan. Excess usage may result in additional charges or service interruption.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">3. Data Processing</h3>
                            <p className="mt-2 text-base text-gray-600">We process and store data in accordance with applicable data protection laws. You are responsible for obtaining necessary consents from your users for data processing.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">4. Service Level Agreement</h3>
                            <p className="mt-2 text-base text-gray-600">We strive to maintain 99.9% uptime. Compensation for downtime is provided as outlined in our SLA document.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">5. Pricing and Payment</h3>
                            <p className="mt-2 text-base text-gray-600">Pricing is based on the plan you select and your usage. Payments are due monthly and must be made via approved payment methods.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">6. Termination</h3>
                            <p className="mt-2 text-base text-gray-600">Either party may terminate the service with 30 days' notice. We reserve the right to terminate service immediately for violations of these terms.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">7. Liability Limitation</h3>
                            <p className="mt-2 text-base text-gray-600">Our liability is limited to the amount paid for the service in the past 12 months. We are not liable for indirect or consequential damages.</p>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black">8. Contact Us</h3>
                            <p className="mt-2 text-base text-gray-600">If you have any questions about these Terms, please contact us at legal@convosearch.com.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms