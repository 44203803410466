import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const headerConfig = {
    logo: {
        src: "/logo.svg",
        alt: "Company Logo"
    },
    navItems: [
        { label: "Pricing", path: "/pricing" },
        { label: "Documentation", path: "https://docs.convosearch.com" },
        { label: "Contact", path: "/contact" },
    ],
    callToAction: [
        { label: "Schedule a call", path: "https://calendar.app.google/cSQHFnqaSLtWcBTv9", primary: false },
        { label: "Get started", path: "https://app.conversecart.com", primary: true },
    ]
};

const Header = () => {
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setExpanded(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <header className="relative z-10 py-2 md:py-3 max-w-7xl mx-auto">
            <div className="container px-4 mx-auto sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between">
                    <div className="flex-shrink-0">
                        <Link to="/" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                            <img className="w-auto h-12 mt-3" src={headerConfig.logo.src} alt={headerConfig.logo.alt} />
                        </Link>
                    </div>

                    <div className="flex lg:hidden">
                        <button type="button" className="text-gray-900" onClick={() => setExpanded(!expanded)} aria-expanded={expanded}>
                            {!expanded ? (
                                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            ) : (
                                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div>

                    <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-8">
                        {headerConfig.navItems.map((item, index) => (
                            <Link key={index} to={item.path} className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                                {item.label}
                            </Link>
                        ))}
                        {headerConfig.callToAction.map((item, index) => (
                            <Link
                                key={index}
                                to={item.path}
                                className={`inline-flex items-center justify-center px-4 py-2 text-md font-bold leading-7 transition-all duration-200 border rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ${
                                    item.primary
                                        ? "text-white bg-black border-transparent hover:bg-gray-600"
                                        : "text-black bg-white border-black hover:bg-gray-100"
                                }`}
                                role="button"
                            >
                                {item.label}
                            </Link>
                        ))}
                    </div>
                </div>

                {expanded && (
                    <nav>
                        <div className="px-1 py-8">
                            <div className="grid gap-y-7">
                                {headerConfig.navItems.map((item, index) => (
                                    <Link key={index} to={item.path} className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                                        {item.label}
                                    </Link>
                                ))}
                                {headerConfig.callToAction.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item.path}
                                        className={`inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 transition-all duration-200 border rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ${
                                            item.primary
                                                ? "text-white bg-gray-900 border-transparent hover:bg-gray-600"
                                                : "text-black bg-white border-black hover:bg-gray-100"
                                        }`}
                                        role="button"
                                    >
                                        {item.label}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </nav>
                )}
            </div>
        </header>
    )
}

export default Header;