import React, { useState } from 'react';

const TestimonalSection = () => {
    return (
        <section className="py-10 bg-black sm:py-16 lg:py-24">
            <h2 className="text-5xl font-semibold font-sans text-center tracking-tight text-gray-50 mb-20">Hear from our satisfied customers</h2>
            <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
                <div className="grid items-center grid-cols-1 lg:items-stretch md:grid-cols-2 gap-y-8 gap-x-12 xl:gap-x-20">
                <div className="relative">
                        <div className="w-full h-[750px]">
                            <video
                                className="object-cover w-full h-full"
                                controls
                                poster="/testimonial-thumbnail.png"
                            >
                                <source src="/CS-Testimonial.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between md:py-5">
                        <blockquote >
                            <h3 className="text-2xl font-semibold text-gray-50 mb-4   rounded-lg">
                                {/* <span className="text-5xl font-bold text-gray-400">"</span> */}
                                ConvoSearch has significantly increased our Conversion Rate!
                                {/* <span className="text-5xl font-bold text-gray-400">"</span> */}

                            </h3>
                            <div className='bg-gray-900 p-3 rounded-md pt-5 mt-10'>
                                <p className="text-xl leading-relaxed text-white mb-4 p-1 rounded-lg">
                                    <span className="text-3xl font-bold text-gray-400">"</span>
                                    ConvoSearch's AI search helps our customers find what they're looking for quicker.
                                    <span className="text-3xl font-bold text-gray-400">"...</span>
                                </p>
                                <p className="text-xl leading-relaxed text-white mb-4 p-1 rounded-lg">
                                    <span className="text-3xl font-bold text-gray-400">"</span>
                                    It's helping our customers search for things like different categories, and colours even if the product does not have that term in the tag and recommend our customers the most suitable products.
                                    <span className="text-3xl font-bold text-gray-400">"...</span>
                                </p>
                                {/* <p className="text-xl leading-relaxed text-white mb-4 p-1 rounded-lg">
                                <span className="text-3xl font-bold text-gray-400">"</span>This has significantly helped our conversion rate.<span className="text-3xl font-bold text-gray-400">"</span>
                            </p> */}
                                <p className="text-xl leading-relaxed text-white p-1 rounded-lg">
                                    <span className="text-3xl font-bold text-gray-400">"</span>
                                    The search experience for our customers is much better experience when using ConvoSearch.
                                    <span className="text-3xl font-bold text-gray-400">"...</span>
                                </p>
                            </div>
                        <div className="mt-12">
                            <p className="text-xl font-semibold text-white">Matt Newman</p>
                            <p className="mt-2 text-base text-gray-400">CEO and Creative Director @ ONEBYONE Clothing Co.</p>
                        </div>
                        </blockquote>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonalSection