import React from 'react';

interface PricingPlan {
    name: string;
    price: number | string;
    features: { [key: string]: string | boolean };
    isPopular?: boolean;
}

const pricingPlans: PricingPlan[] = [
    {
        name: 'Free Forever',
        price: 'Free',
        features: {
            'Maximum Indices': '1',
            'Maximum images stored': '100',
            'Maximum records': '500',
            'Maximum Searches/Month': '500',
            'Priority Speed': false,
            'Advanced Analytics': false,
            'Recommendations': false,
            'Support': 'Basic Chat Support',
            'Custom Fine-tuned models': false,
        },
    },
    {
        name: 'Premium',
        price: '$49.99',
        isPopular: false,
        features: {
            'Maximum Indices': '5',
            'Maximum images stored': '1000',
            'Maximum records': '2500',
            'Maximum Searches/Month': '2500',
            'Priority Speed': true,
            'Advanced Analytics': true,
            'Recommendations': false,
            'Support': 'Premium Support',
            'Custom Fine-tuned models': false,
        },
    },
    {
        name: 'Scale (Usage based)',
        price: 'Custom',
        isPopular: true,
        features: {
            'Maximum Indices': 'Unlimited',
            'Maximum images stored': 'Unlimited',
            'Maximum records': 'Unlimited',
            'Maximum Searches/Month': 'Unlimited',
            'Priority Speed': true,
            'Advanced Analytics': true,
            'Recommendations': true,
            'Support': '24/7 Team on Duty',
            'Custom Fine-tuned models': false,
        },
    },
    {
        name: 'Enterprise',
        price: 'Custom',
        features: {
            'Maximum Indices': 'Unlimited',
            'Maximum images stored': 'Unlimited',
            'Maximum records': 'Unlimited',
            'Maximum Searches/Month': 'Unlimited',
            'Priority Speed': true,
            'Advanced Analytics': true,
            'Recommendations': true,
            'Support': '24/7 Team on Duty',
            'Custom Fine-tuned models': true,
        },
    },
];

const PricingSection: React.FC = () => {
    return (
        <section className="py-10 bg-white sm:py-16 lg:py-24">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto text-center">
                    <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">Pricing &amp; Plans</h2>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">Choose your plan: Free, Premium (flat fee), Scale (usage-based), or Enterprise (custom). Simple pricing for every need.</p>
                </div>
                {/* Table for lg+ screens */}
                <div className="hidden mt-16 lg:block">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="py-8 pr-4"></th>
                                {pricingPlans.map((plan) => (
                                    <th key={plan.name} className={`px-4 py-8 text-center ${plan.isPopular ? 'bg-gray-900 rounded-t-xl' : ''}`}>
                                        {/* {plan.isPopular && <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">Popular</span>} */}
                                        {<span className={`text-base font-medium ${plan.isPopular ? 'text-white px-10 py-2 bg-blue-600 rounded-full' : 'text-blue-600'}`}>{plan.name}</span>}
                                        <p className={`mt-6 text-6xl font-bold ${plan.isPopular ? 'text-white' : ''}`}>{plan.price}</p>
                                        <p className={`mt-2 text-base font-normal ${plan.isPopular ? 'text-gray-200' : 'text-gray-500'}`}>Per month</p>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(pricingPlans[0].features).map((feature) => (
                                <tr key={feature}>
                                    <td className="py-4 pr-4 font-medium border-b border-gray-200">{feature}</td>
                                    {pricingPlans.map((plan) => (
                                        <td key={`${plan.name}-${feature}`} className={`px-4 py-4 text-center border-b ${plan.isPopular ? 'text-white bg-gray-900 border-white/20' : 'border-gray-200'}`}>
                                            {typeof plan.features[feature] === 'boolean'
                                                ? (plan.features[feature]
                                                    ? <svg className="w-5 h-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                    : '-')
                                                : plan.features[feature]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td className="py-6 pr-4"></td>
                                {pricingPlans.map((plan) => (
                                    <td key={`${plan.name}-action`} className={`px-4 py-6 text-center ${plan.isPopular ? 'text-white bg-yellow-500 rounded-b-xl' : ''}`}>
                                        <a href="#" title="" className={`inline-flex items-center font-semibold ${plan.isPopular ? 'text-white' : 'text-blue-600 hover:text-blue-700'}`}>
                                            Get Started
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </a>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Cards for xs to lg screens */}
                <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-2 lg:hidden">
                    {pricingPlans.map((plan) => (
                        <div key={plan.name} className={`p-6 rounded-lg shadow ${plan.isPopular ? 'bg-gray-900 text-white' : 'bg-white'}`}>
                            <h3 className="text-2xl font-bold">{plan.name}</h3>
                            <p className="mt-4 text-4xl font-bold">{plan.price === 'Custom' || plan.price === 'Free' ? '' : '$'}{plan.price}<span className="text-base font-normal">/month</span></p>
                            <ul className="mt-6 space-y-4">
                                {Object.entries(plan.features).map(([feature, value]) => (
                                    <li key={feature} className="flex items-center">
                                        {typeof value === 'boolean' ? (
                                            value ? (
                                                <svg className="w-5 h-5 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                </svg>
                                            ) : (
                                                <svg className="w-5 h-5 mr-2 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            )
                                        ) : null}
                                        <span>{feature}: {value}</span>
                                    </li>
                                ))}
                            </ul>
                            <button className={`mt-8 w-full py-2 px-4 rounded ${plan.isPopular ? 'bg-yellow-500 text-white' : 'bg-blue-600 text-white'}`}>
                                Get Started
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </section >
    );
};

export default PricingSection;