import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface FooterLink {
    title: string;
    url: string;
}

interface FooterSection {
    title: string;
    links: FooterLink[];
}

const Footer = () => {
    const [footerSections, setFooterSections] = useState<FooterSection[]>([
        {
            title: "Platform",
            links: [
                { title: "Home", url: "/" },
                { title: "For Enterprises", url: "/enterprises" },
                { title: "Search Agents", url: "/agents" },
            ]
        },
        {
            title: "Resources",
            links: [
                { title: "Pricing & Plans", url: "/pricing" },
                { title: "Documentation", url: "https://docs.convosearch.com" },
                { title: "Blog", url: "https://docs.convosearch.com" },
            ]
        },
        {
            title: "Company",
            links: [
                { title: "Terms & Conditions", url: "/terms" },
                { title: "Privacy Policy", url: "/privacy-policy" },
                { title: "Contact", url: "/contact" },
            ]
        }
    ]);

    const FooterColumn = ({ section }: { section: FooterSection }) => (
        <div>
            <h6 className="text-base font-semibold text-white">{section.title}</h6>
            <ul className="mt-6 space-y-4">
                {section.links.map((link, index) => (
                    <li key={index}>
                        <Link to={link.url} className="flex text-base font-normal text-gray-400 transition-all transform hover:text-white duration hover:translate-x-1">
                            {link.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
    return (
        <footer className="relative py-12 overflow-hidden bg-black lg:py-20 xl:py-24 sm:py-16">
            <div className="absolute bottom-0 left-0 transform -translate-x-12 translate-y-80 lg:translate-x-0">
                <svg className="opacity-60 blur-3xl filter" style={{ filter: 'blur(64px)' }} width="612" height="396" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M225.569 296.184c-129.505-34.7-253.236 49.082-218.753-79.613C41.3 87.876 436.205-28.039 565.71 6.662c129.505 34.701-55.432 206.876-89.916 335.571-34.484 128.695-120.721-11.348-250.225-46.049Z" fill="url(#c)" />
                    <defs>
                        <linearGradient id="c" x1="623.268" y1="22.085" x2="453.193" y2="459.449" gradientUnits="userSpaceOnUse">
                            <stop offset="0%" style={{ stopColor: 'var(--color-cyan-500)' }} />
                            <stop offset="100%" style={{ stopColor: 'var(--color-purple-500)' }} />
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div className="absolute inset-0">
                <img className="object-cover w-full h-full opacity-50" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/noise.png" alt="" />
            </div>

            <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-2 xl:grid-cols-7 gap-x-8 xl:gap-x-16">
                    <div className="max-w-md mx-auto text-center xl:col-span-3 lg:max-w-sm lg:mx-0 lg:text-left ">
                        <img src="/dark-logo.svg" alt="ConvoSearch Logo" className="w-full max-w-xs mx-auto lg:mx-0" />
                        <div className="relative inline-flex items-center justify-center mt-8 sm:mt-12 group">
                            <div className="absolute transition-all duration-200 rounded-md -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                            <Link to="/trial" className="relative inline-flex items-center justify-center px-8 py-3 text-base font-normal text-white bg-black border border-transparent rounded-md" role="button">
                                Get started for Free
                            </Link>
                        </div>
                    </div>
                    <div className="overflow-hidden lg:-ml-12 lg:-my-8 bg-base-900 rounded-xl xl:col-span-4">
                        <div className="p-8 sm:p-12">
                            <div className="grid grid-cols-2 gap-y-12 sm:grid-cols-3 gap-x-12 xl:gap-x-16">
                                {footerSections.map((section, index) => (
                                    <FooterColumn key={index} section={section} />
                                ))}
                            </div>



                            <hr className="mt-12 border-gray-800 sm:mt-16" />

                            <div className="flex items-center justify-between mt-6">
                                <span className="text-lg font-normal text-gray-400"> Follow us on: </span>

                                <div className="flex items-center justify-end space-x-4">
                                    <a href="#" title="X" target="_blank" rel="noopener" className="inline-flex items-center justify-center w-8 h-8 text-white transition-all bg-transparent rounded-full hover:bg-gray-800 duraiton-200">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932L18.901 1.153ZM17.61 20.644h2.039L6.486 3.24H4.298L17.61 20.644Z" />
                                        </svg>
                                    </a>

                                    <a href="#" title="LinkedIn" target="_blank" rel="noopener" className="inline-flex items-center justify-center w-8 h-8 text-white transition-all bg-transparent rounded-full hover:bg-gray-800 duraiton-200">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                                        </svg>
                                    </a>

                                    <a href="#" title="YouTube" target="_blank" rel="noopener" className="inline-flex items-center justify-center w-8 h-8 text-white transition-all bg-transparent rounded-full hover:bg-gray-800 duraiton-200">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                                        </svg>
                                    </a>
                                    <a href="#" title="GitHub" target="_blank" rel="noopener" className="inline-flex items-center justify-center w-8 h-8 text-white transition-all bg-transparent rounded-full hover:bg-gray-800 duraiton-200">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer