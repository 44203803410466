import React, { useState } from 'react';

const AnalytisFeaturesSection = () => {
    const [activeTab, setActiveTab] = useState('Usage and Conversion Patterns');

    const tabs = [
        'Usage and Conversion Patterns',
        'Keywords Trends',
        'Top Searched Products',
    ];

    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">Insightful Analytics for Your Business</h2>
                    <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">ConvoSearch's dashboard provides comprehensive analytics to help you understand your customers better. Explore usage and conversion patterns, discover top searched keywords, and identify your most popular products.</p>
                </div>

                <div className="max-w-4xl mx-auto mt-12 sm:mt-16">
                    <nav className="flex flex-col items-center justify-center space-y-5 text-center sm:flex-row sm:space-y-0 sm:space-x-10 md:space-x-16">
                        {tabs.map((tab) => (
                            <a
                                key={tab}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveTab(tab);
                                }}
                                className={`pb-2 text-xs font-semibold tracking-widest uppercase transition-all duration-200 border-b ${
                                    activeTab === tab
                                        ? 'text-blue-600 border-blue-600'
                                        : 'text-gray-400 border-transparent hover:text-gray-900'
                                }`}
                            >
                                {tab}
                            </a>
                        ))}
                    </nav>

                    <div className="mt-6 sm:mt-8">
                        <img className="w-full shadow-2xl rounded-2xl" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/features/4/dashboard-mockup.svg" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AnalytisFeaturesSection;